const SHEET_URL = 'https://script.google.com/macros/s/AKfycbz_VDZ1R0ITkEATft49YZzX4ZT-kcmrNyvBF17kUQD36jLfiqImb2s3z_qCuJWOJ-oWxQ/exec';

export const submitToSheet = async (formData) => {
  try {
    await fetch(SHEET_URL, {
      method: 'POST',
      body: JSON.stringify(formData),
      mode: 'no-cors', // Required for cross-origin requests
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Since no-cors mode doesn't return readable response
    // We'll assume success if no error is thrown
    return { success: true };
  } catch (error) {
    console.error('Submission error:', error);
    throw new Error('NETWORK_ERROR');
  }
};