import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import DefaultLayout from './layouts/DefaultLayout';
import HomePage from './pages/index';
import RegistrationPage from './pages/registration';


function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <DefaultLayout>
              <HomePage />
            </DefaultLayout>
          }
        />
        <Route
          path="/register"
          element={
            <DefaultLayout>
              <RegistrationPage />
            </DefaultLayout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
