export const validateCategory = (category) => {
  return Object.keys(businessCategories).includes(category);
};

export const businessCategories = {
  transport: {
    label: "Transport & Automotive Sector",
    subcategories: [
      "Logistics and Cargo Company of the year",
      "Clearing and Forwarding Company of the year",
      "Shipping Company of the year",
      "Best Railway Company of the year",
      "Best private Transportation/car hire company of the year",
      "Most Trusted Online Cab Service of the year",
      "Shipping Agency of the year",
      "Best Passenger Road of the year",
      "Best Transportation Company of the year",
      "Transportation Company in East Africa for Customer service",
      "Car importer of the year (Used)",
      "Car importer of the year (Brand New)",
      "Best Car Rental Service of the year",
      "Best Courier Services Provider of the year",
      "Best Wide Upcoming Transportation Company of the year (1-5 years of experience)",
    ],
  },
  agriculture: {
    label: "Agriculture Sector",
    subcategories: [
      "Agripreneur founder of the year (Overall)",
      "Best fishing Company of the year",
      "Best Farming Company of the year",
      "Fertilizer Supplier of the year",
      "Best Livestock Farming Company of the year",
      "Agriculture Technology Innovator of the year",
      "Seedling/Seeds Manufacturer of the year",
      "Irrigation Services Provider of the year",
      "Agriculture Equipment Supplier of the year",
      "Agriculture Equipment Manufacturer of the year",
      "Agro-Processing Company of the year",
      "Agribusiness Company of the year",
      "Best Agriculture Exporter Company of the year", // Fixed typo: Expoter -> Exporter
      "Horticulture Company of the year",
      "Organic Farming Pioneer of the year",
      "Sustainable Agriculture Initiative of the year",
      "Best Wide Upcoming Agriculture Company of the year (1-5 years of experience)",
    ],
  },
  creative: {
    label: "Creativity, Fashion & Media Sector",
    subcategories: [
      "Best Advertising Agency of the year",
      "Fashionpreneur of the year (Overall)",
      "Best Male Model of the year",
      "Best Female Model of the year",
      "Best Designer of the year",
      "Most Innovative Fashion Brand of the year",
      "Best Suit Brand Office of the year",
      "Best Female Clothing Store",
      "Best Male Clothing Store",
      "Best Footware Store",
      "Best Wedding Solutions Store",
      "Best Photography and videography Studio of the year",
      "Best Firm Production of the year",
      "Outstanding Cultural Heritage Promotion initiative",
      "Leading Digital Content Creator of the year",
      "Best Printing & General Supply of the year",
      "Best Creative Baking & Cakes",
    ],
  },
  home: {
    label: "Home Life & Distribution Sector",
    subcategories: [
      "Home Decor Supplier of the year",
      "Best Furniture Project of the year",
      "Furniture Manufacturer and Supplier of the year (Overall)",
      "Best Wide Upcoming Furniture Manufacturer (1-5 years of experience)",
      "Best Running Offer in Furnitures of the year",
      "Outstanding Local East African Furniture Manufacturer of the year",
      "Best Furniture Outlet/Showroom of the year",
      "Home Appliance Supplier of the year",
      "Cabinet Supplier of the year",
      "Home Applience Retailer of the year",
      "Home Lighting Solutions Provider of the year",
      "Electronic Household Equipment Supplier of the year",
      "Carpet Supplier of the year",
    ],
  },
  cosmetics: {
    label: "Cosmetics & Beauty Sector",
    subcategories: [
      "Best Cosmetics Founder of the year (Overall)",
      "Best Make Up Artist of the year",
      "Best Hair Staylist founder of the year",
      "Best Hair Cutting of the year",
      "Best Beauty Collage of the year",
      "Best Natural Cosmetics Office",
      "Best Artificial Cosmetics Office",
      "Best Wide Upcoming Cosmetics Founder of the year (1-5 years of experience)",
    ],
  },
  events: {
    label: "Events & Hospitality Sector",
    subcategories: [
      "Hospitality Founder of the year (Overall)",
      "Best Event Management Company of the year",
      "Best Event Planner of the year",
      "Best Event Planning Project of the year",
      "Best Catering Office of the year",
      "Best Conference Venue of the year",
      "Best Wedding Venue of the year",
      "Best Dining Restaulant of the year",
      "Best Tour Operator of the year",
      "Best Travel Agency of the year",
      "Best Adventure Tourism Company",
      "Educationpreneur of the year",
    ],
  },
  health: {
    label: "Health Sector",
    subcategories: [
      "Hospital of the year",
      "Healthpreneur of the year (Overall)",
      "Pharmacy of the year (Overall)",
      "Biopharma Company of the year",
      "Best Mental health Services Provider",
      "Outstanding Health Technology Innovator",
      "Biotech Company of the year",
      "Best Herbal Clinic of the year",
    ],
  },
  construction: {
    label: "Construction And Real Estate Sector",
    subcategories: [
      "Best Real Estate Company of the year (Overall)",
      "Best Construction Company of the year (Overall)",
      "Best Wide Upcoming Real Estate Company of the year (1-5 years of experience)",
      "Best Wide Upcoming Construction Company of the year (1-5 years of experience)",
      "Real Estate Management Company of the year",
      "Best Construction Safety Standards Firm",
      "Affordable Housing Developer of the year",
      "Landscape Contractors Company of the year",
      "Architect Firm of the year",
      "Property Developer of the year",
      "Property Management Company of the year",
      "Waste Management Company of the year",
      "Air Conditioning & Heating Contractors of the year",
      "East Africa's Best Cleaning & planning Company of the year",
      "Best Interior Design Company of the year",
      "Best Bulding Materials Supplier of the year",
      "Best Bulding Material Manufacturer of the year",
      "Luxury Real Estate Developer of the year",
      "Urban Planning Consultancy Firm of the year",
      "Best Real Estate Company in Customer service (Hospitality)",
      "Best Real Estate Project/Plots of the year",
      "Best Creative Real Estate Company of the year",
      "Best Creative Construction Company of the year",
      "Best Running Offer of the year in Real Estate",
    ],
  },
  social: {
    label: "General Social Sector",
    subcategories: [
      "Founder of the year",
      "Upcoming young CEO",
      "Best General Manager of the year",
      "Best Office Seller of the year",
      "Agripreneur of the year",
      "Healthpreneur of the year",
      "Fashionpreneur of the year",
      "Hospitality founder of the year",
      "Cosmetics founder of the year",
      "Entertainment founder of the year",
      "Manufacturing founder of the year",
      "Retail founder of the year",
      "Butcherpreneur founder of the year",
      "Caterepreneur founder of the year",
      "Best Female Clothing store", // Fixed typo: Flothing -> Clothing
      "Best Male Clothing store",
      "Best Content Creator of the year",
      "Best Real Estate Company of the year",
      "Make up Artist of the year",
      "Events and Management of the year",
      "Extraordinary Founder of the year",
      "Outstanding Business Man of the year",
      "Outstanding Business Woman of the year",
      "Techpreneur of the year",
      "Social founder of the year",
      "Edupreneur of the year",
      "Mediapreneur of the year",
      "Realtor of the year",
      "Youth Choice Company of the year",
      "Most promosing founder of the year",
      "Best Furniture Company of the year", // Fixed typo: yhe -> the
      "Best Non Organization",
      "Best security company of the year", // Fixed typo: sercurity -> security
    ],
  },
};

export const getSubcategories = (category) => {
  if (!validateCategory(category)) {
    return [];
  }
  return businessCategories[category].subcategories;
};
