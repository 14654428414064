import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col, Modal, Button } from "react-bootstrap";
import banner1 from "../assets/images/banners/banner1.jpg";
import { countries } from "../data/countries";
import { businessCategories } from "../data/businessCategories";
import logo from "../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { validateCategory } from "../data/businessCategories";
import { submitToSheet } from "../services/sheetService";

const RegistrationPage = () => {
  // State management
  const [selectedCategory, setSelectedCategory] = useState("");
  const [yearsOperation, setYearsOperation] = useState(null);
  const [description, setDescription] = useState("");
  const [formData, setFormData] = useState({
    businessName: "",
    businessAddress: "",
    category: "",
    subCategory: "",
    isRegistered: "",
  });
  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const errorMessages = {
    NETWORK_ERROR:
      "Unable to connect. Please check your internet connection and try again.",
    TIMEOUT_ERROR: "The request is taking too long. Please try again.",
    FORM_ERROR: "Please check all fields and try again.",
    UNKNOWN_ERROR: "Something went wrong. Please try again later.",
  };

  const {
    register,
    formState: { errors, isDirty: formIsDirty },
    handleSubmit,
    trigger,
    reset, // Add reset from useForm
  } = useForm({
    mode: "onTouched", // This will validate on blur and submit
    reValidateMode: "onChange", // This will revalidate while typing
  });

  // Add this function to handle form errors
  const onError = (errors) => {
    const firstError = Object.keys(errors)[0];
    const errorElement = document.getElementsByName(firstError)[0];
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      errorElement.focus();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
        return e.returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isDirty]);

  useEffect(() => {
    setIsDirty(formIsDirty);
  }, [formIsDirty]);

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    setIsDirty(false);
    // Reset all local state
    setYearsOperation(null);
    setDescription("");
    setPrivacyConsent(false);
    setFormData({
      ...formData,
      isRegistered: "",
    });
    // Reset form
    reset();
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      if (!navigator.onLine) {
        throw new Error("NETWORK_ERROR");
      }

      // Get the category label from businessCategories
      const categoryLabel = data.businessCategory
        ? businessCategories[data.businessCategory].label
        : "";

      const formPayload = {
        fullName: data.fullName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        age: data.age,
        gender: data.gender,
        country: data.country,
        businessName: data.businessName,
        businessAddress: data.businessAddress,
        businessCategory: categoryLabel,
        subCategory: data.subCategory,
        yearsOperation: yearsOperation,
        isRegistered: data.isRegistered,
        description: description,
      };

      // Add timeout for the request
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("TIMEOUT_ERROR")), 30000)
      );

      const submitPromise = submitToSheet(formPayload);
      await Promise.race([submitPromise, timeoutPromise]);

      // If successful, show success modal and reset
      setShowSuccessModal(true);
      setIsDirty(false);
      reset();
      setYearsOperation(null);
      setDescription("");
      setPrivacyConsent(false);
      setFormData({
        ...formData,
        isRegistered: "",
      });
    } catch (error) {
      console.error("Submission error:", error);
      let message;
      switch (error.message) {
        case "NETWORK_ERROR":
          message = errorMessages.NETWORK_ERROR;
          break;
        case "TIMEOUT_ERROR":
          message = errorMessages.TIMEOUT_ERROR;
          break;
        default:
          message = errorMessages.UNKNOWN_ERROR;
      }
      setErrorMessage(message);
      setShowErrorModal(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle category change and update subcategories
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    setFormData({
      ...formData,
      category,
      subCategory: "", // Reset subcategory when category changes
    });
  };

  // Get subcategories based on selected category
  const getSubcategories = () => {
    if (!selectedCategory || !businessCategories[selectedCategory]) {
      return [];
    }
    return businessCategories[selectedCategory].subcategories;
  };

  // Add Success Modal component
  const SuccessModal = () => (
    <Modal
      show={showSuccessModal}
      onHide={handleCloseModal}
      centered
      style={{ zIndex: 9999 }}
      contentClassName="position-relative"
    >
      <Modal.Header style={{ background: "#6e1920", color: "white" }}>
        <Modal.Title style={{ width: "100%", textAlign: "center" }}>
          Thank You for Your Submission!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-4">
          <i
            className="fas fa-check-circle text-success"
            style={{ fontSize: "3rem" }}
          ></i>
          <h5 className="mt-4">Your form has been successfully submitted.</h5>

          <div className="mt-4">
            <h6 className="fw-bold">Next Steps:</h6>
            <p>Please contact the Number below</p>
            <p className="mb-1">To be added to the WhatsApp group.</p>
            <div
              className="contact-number p-3 my-3"
              style={{
                backgroundColor: "#f8f9fa",
                borderRadius: "8px",
                border: "1px dashed #6e1920",
                cursor: "pointer",
                transition: "all 0.2s ease",
              }}
              onClick={() => (window.location.href = "tel:+255653077768")}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#f0f0f0")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#f8f9fa")
              }
            >
              <a
                href="tel:+255653077768"
                style={{
                  color: "#6e1920",
                  textDecoration: "none",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <i className="fas fa-phone-alt me-2"></i>
                <span style={{ fontSize: "1.25rem", fontWeight: "500" }}>
                  +255 653 077 768
                </span>
              </a>
            </div>

            <p className="mb-4">
              We will confirm receipt of your payment and add you to the group
              accordingly.
            </p>

            <p className="text-muted" style={{ fontSize: "0.9rem" }}>
              Thank you for your cooperation!
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleCloseModal}
          style={{
            backgroundColor: "#6e1920",
            border: "none",
            width: "100px",
          }}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );

  // Add Error Modal component
  const ErrorModal = () => (
    <Modal
      show={showErrorModal}
      onHide={() => setShowErrorModal(false)}
      centered
      style={{ zIndex: 9999 }}
      contentClassName="position-relative"
    >
      <Modal.Header
        style={{
          background: "#dc3545",
          color: "white",
          display: "flex",
          justifyContent: "center",
          paddingTop: "1.5rem",
          paddingBottom: "1.5rem",
        }}
      >
        <Modal.Title style={{ width: "100%", textAlign: "center" }}>
          Unable to Submit Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-4">
          <i
            className="fas fa-exclamation-circle"
            style={{
              fontSize: "3rem",
              color: "#dc3545",
            }}
          ></i>
          <div className="mt-4">
            <p
              className="error-message"
              style={{ fontSize: "1.1rem", color: "#666" }}
            >
              {errorMessage}
            </p>
            <p className="mt-3" style={{ fontSize: "0.9rem", color: "#888" }}>
              Your form data has been preserved. Click Try Again to return to
              the form.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="danger"
          onClick={() => setShowErrorModal(false)}
          style={{
            width: "120px",
            backgroundColor: "#dc3545",
            border: "none",
          }}
        >
          Try Again
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div
      className="registration-container"
      style={{
        minHeight: "calc(100vh - 200px)",
        marginTop: "80px",
        padding: "2rem 0",
        backgroundColor: "#f0f0f0",
      }}
    >
      <Container>
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          {/* Banner Section */}
          <div
            className="registration-banner"
            style={{
              width: "100%",
              marginBottom: "2rem",
              height: "200px",
              backgroundImage: `url(${banner1})`,
              backgroundSize: "contain", // Changed from 'cover' to 'contain'
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat", // Added to prevent repeating
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              backgroundColor: "#f8f9fa", // Added background color for empty space
            }}
          />

          <h3 className="text-center mb-4">Registration Form</h3>

          <Form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
            {/* Personal Information Section */}
            <div
              className="form-section mb-4"
              style={{
                background: "#fff",
                padding: "2rem",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                marginBottom: "2rem",
              }}
            >
              <h4
                className="mb-4"
                style={{
                  color: "#6e1920",
                  borderBottom: "2px solid #6e1920",
                  paddingBottom: "0.5rem",
                }}
              >
                Personal Information
              </h4>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Full Name
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545", // Error color
                        }}
                      >
                        {errors.fullName?.message &&
                          `*${errors.fullName.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="fullName" // Add this
                      placeholder="Enter your full name"
                      {...register("fullName", {
                        required: "Full Name is required",
                        minLength: {
                          value: 2,
                          message: "Minimum 2 characters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maximum 50 characters",
                        },
                        validate: {
                          twoNames: (value) => {
                            const names = value?.trim().split(/\s+/);
                            return (
                              names.length >= 2 ||
                              "Please enter both first and last name"
                            );
                          },
                        },
                      })}
                      isInvalid={!!errors.fullName}
                      onBlur={() => trigger("fullName")} // Add this for immediate validation
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Email
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.email?.message && `*${errors.email.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      isInvalid={!!errors.email}
                      onBlur={() => trigger("email")}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Phone Number
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.phoneNumber?.message &&
                          `*${errors.phoneNumber.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      name="phoneNumber"
                      placeholder="Enter your phone number"
                      {...register("phoneNumber", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9+\-() ]*$/,
                          message: "Only numbers and phone symbols allowed",
                        },
                        validate: {
                          validFormat: (value) => {
                            // Remove all non-numeric characters to count digits
                            const digitsOnly = value.replace(/\D/g, "");
                            if (digitsOnly.length < 10) {
                              return "Phone number must have at least 10 digits";
                            }
                            if (digitsOnly.length > 15) {
                              return "Phone number must not exceed 15 digits";
                            }
                            return true;
                          },
                        },
                        onChange: (e) => {
                          // Replace any non-allowed characters immediately
                          e.target.value = e.target.value.replace(
                            /[^0-9+\-() ]/g,
                            ""
                          );
                        },
                      })}
                      isInvalid={!!errors.phoneNumber}
                      onBlur={() => trigger("phoneNumber")}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Age
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.age?.message && `*${errors.age.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="age"
                      placeholder="Enter your age"
                      {...register("age", {
                        required: "Age is required",
                        min: {
                          value: 15,
                          message: "Minimum age is 15 years",
                        },
                        max: {
                          value: 50,
                          message: "Maximum age is 50 years",
                        },
                        validate: {
                          isNumber: (value) =>
                            !isNaN(value) || "Please enter a valid number",
                        },
                      })}
                      isInvalid={!!errors.age}
                      onBlur={() => trigger("age")}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Gender
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.gender?.message && `*${errors.gender.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Select
                      {...register("gender", {
                        required: "Gender is required",
                      })}
                      isInvalid={!!errors.gender}
                    >
                      <option value="">Select gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Country
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.country?.message &&
                          `*${errors.country.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Select
                      {...register("country", {
                        required: "Country is required",
                      })}
                      isInvalid={!!errors.country}
                    >
                      <option value="">Select country</option>
                      {countries.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            {/* Business Information Section */}
            <div
              className="form-section mb-4"
              style={{
                background: "#fff",
                padding: "2rem",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4
                className="mb-4"
                style={{
                  color: "#6e1920",
                  borderBottom: "2px solid #6e1920",
                  paddingBottom: "0.5rem",
                }}
              >
                Business Information
              </h4>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Business Name
                      <br />
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.businessName?.message &&
                          `*${errors.businessName.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="businessName"
                      placeholder="Enter business name"
                      {...register("businessName", {
                        required: "Business name is required",
                        minLength: {
                          value: 2,
                          message:
                            "Business name must be at least 2 characters",
                        },
                        maxLength: {
                          value: 100,
                          message:
                            "Business name must not exceed 100 characters",
                        },
                      })}
                      isInvalid={!!errors.businessName}
                      onBlur={() => trigger("businessName")}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Business Address
                      <br />
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.businessAddress?.message &&
                          `*${errors.businessAddress.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="businessAddress"
                      placeholder="Enter business address"
                      {...register("businessAddress", {
                        required: "Business address is required",
                        minLength: {
                          value: 5,
                          message: "Address must be at least 5 characters",
                        },
                        maxLength: {
                          value: 200,
                          message: "Address must not exceed 200 characters",
                        },
                      })}
                      isInvalid={!!errors.businessAddress}
                      onBlur={() => trigger("businessAddress")}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Business Category
                      <br />
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.businessCategory?.message &&
                          `*${errors.businessCategory.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Select
                      {...register("businessCategory", {
                        required: "Business category is required",
                        validate: {
                          validCategory: (value) =>
                            validateCategory(value) ||
                            "Invalid category selected",
                        },
                        onChange: (e) => {
                          handleCategoryChange(e);
                          setTimeout(() => {
                            trigger("businessCategory");
                            trigger("subCategory");
                          }, 0);
                        },
                      })}
                      isInvalid={!!errors.businessCategory}
                    >
                      <option value="">Select category</option>
                      {Object.entries(businessCategories).map(
                        ([key, category]) => (
                          <option key={key} value={key}>
                            {category.label}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Sub Category
                      <br />
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.subCategory?.message &&
                          `*${errors.subCategory.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Select
                      {...register("subCategory", {
                        required: "Sub category is required",
                        validate: {
                          validSubCategory: (value) => {
                            if (!selectedCategory)
                              return "Please select a category first";
                            const validSubs =
                              getSubcategories(selectedCategory);
                            return (
                              validSubs.includes(value) ||
                              "Invalid sub-category selected"
                            );
                          },
                        },
                        onChange: () => {
                          setTimeout(() => trigger("subCategory"), 0);
                        },
                      })}
                      disabled={!selectedCategory}
                      isInvalid={!!errors.subCategory}
                    >
                      <option value="">Select sub category</option>
                      {getSubcategories(selectedCategory).map(
                        (subCategory, index) => (
                          <option key={index} value={subCategory}>
                            {subCategory}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Years in Operation: {yearsOperation} <br />
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.yearsOperation?.message &&
                          `*${errors.yearsOperation.message}*`}
                      </span>
                    </Form.Label>
                    <div className="d-flex align-items-center">
                      <Form.Range
                        {...register("yearsOperation", {
                          required: "Years in operation is required",
                          min: {
                            value: 1,
                            message: "Minimum 1 year",
                          },
                          max: {
                            value: 50,
                            message: "Maximum 50 years",
                          },
                          validate: {
                            interacted: (value) => {
                              // Only show error if field has been touched
                              if (yearsOperation === null) return true;
                              return (
                                yearsOperation !== 0 ||
                                "Please select years in operation"
                              );
                            },
                          },
                          onChange: (e) => {
                            const value = parseInt(e.target.value);
                            setYearsOperation(value);
                            // Trigger validation immediately
                            setTimeout(() => trigger("yearsOperation"), 0);
                          },
                        })}
                        min={1}
                        max={50}
                        value={yearsOperation || 1}
                        style={{
                          borderColor: errors.yearsOperation
                            ? "#dc3545"
                            : undefined,
                        }}
                      />
                    </div>
                    {yearsOperation !== null && yearsOperation === 0 && (
                      <Form.Text
                        className="text-danger"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Please select years in operation
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Is Your Business Registered?
                      <br />
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.isRegistered?.message &&
                          `*${errors.isRegistered.message}*`}
                      </span>
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        id="registered-yes"
                        {...register("isRegistered", {
                          required: "Select One",
                          onChange: (e) => {
                            setFormData({
                              ...formData,
                              isRegistered: e.target.value,
                            });
                            trigger("isRegistered");
                          },
                        })}
                        label="Yes"
                        value="yes"
                        checked={formData.isRegistered === "yes"}
                        isInvalid={!!errors.isRegistered}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        id="registered-no"
                        {...register("isRegistered")}
                        label="No"
                        value="no"
                        checked={formData.isRegistered === "no"}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            isRegistered: e.target.value,
                          });
                          trigger("isRegistered");
                        }}
                        isInvalid={!!errors.isRegistered}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Brief Description of Your Business
                      <br />
                      <span
                        style={{
                          marginLeft: "0.2rem",
                          fontSize: "0.6rem",
                          color: "#dc3545",
                        }}
                      >
                        {errors.description?.message &&
                          `*${errors.description.message}*`}
                      </span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      placeholder="Describe your business..."
                      maxLength={1000}
                      {...register("description", {
                        required: "Business description is required",
                        minLength: {
                          value: 50,
                          message: "Description must be at least 50 characters",
                        },
                        maxLength: {
                          value: 1000,
                          message:
                            "Description must not exceed 1000 characters",
                        },
                        onChange: (e) => {
                          setDescription(e.target.value);
                          trigger("description");
                        },
                      })}
                      value={description}
                      isInvalid={!!errors.description}
                      style={{
                        minHeight: "150px",
                        resize: "vertical",
                      }}
                    />
                    <Form.Text
                      className={
                        errors.description ? "text-danger" : "text-muted"
                      }
                    >
                      {description.length}/1000 characters
                      {description.length < 50 &&
                        description.length > 0 &&
                        " (Minimum 50 characters required)"}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            {/* Payment Information Section */}
            <div
              className="form-section mb-4"
              style={{
                background: "#fff",
                padding: "2rem",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4
                className="mb-4"
                style={{
                  color: "#6e1920",
                  borderBottom: "2px solid #6e1920",
                  paddingBottom: "0.5rem",
                }}
              >
                Payment Information
              </h4>

              <div className="payment-instructions text-center mb-5">
                <div
                  className="instruction-box p-4 mb-4"
                  style={{
                    backgroundColor: "#f8f9fa",
                    borderRadius: "8px",
                    border: "1px solid #e9ecef",
                  }}
                >
                  <i
                    className="fas fa-info-circle mb-3"
                    style={{
                      fontSize: "2rem",
                      color: "#6e1920",
                    }}
                  ></i>
                  <h5 className="mb-3">Payment Instructions</h5>
                  <p className="mb-3">
                    After making the payment and completing the form, please
                    contact:
                  </p>
                  <div
                    className="contact-number p-3 mb-3"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "6px",
                      display: "inline-block",
                      border: "1px dashed #6e1920",
                    }}
                  >
                    <i
                      className="fas fa-phone-alt me-2"
                      style={{ color: "#6e1920" }}
                    ></i>
                    <span
                      style={{
                        fontSize: "1.25rem",
                        color: "#6e1920",
                        fontWeight: "500",
                      }}
                    >
                      +255 653 077 768
                    </span>
                  </div>
                  <p>
                    Please send a screenshot of the payment receipt along with
                    your firm's logo.
                  </p>
                </div>

                <h5 className="mb-4" style={{ color: "#6e1920" }}>
                  Payment Methods
                </h5>

                <div className="row g-4">
                  {/* M-PESA Payment Method */}
                  <div className="col-md-6">
                    <div
                      className="payment-method h-100"
                      style={{
                        backgroundColor: "#f8f9fa",
                        borderRadius: "8px",
                        padding: "1.5rem",
                        border: "1px solid #e9ecef",
                        transition: "transform 0.2s ease",
                        cursor: "pointer",
                      }}
                    >
                      <div className="method-icon mb-3">
                        <i
                          className="fas fa-mobile-alt"
                          style={{
                            fontSize: "2rem",
                            color: "#6e1920",
                          }}
                        ></i>
                      </div>
                      <h6 className="method-title mb-4">Vodacom (M-PESA)</h6>
                      <div
                        className="payment-details"
                        style={{
                          backgroundColor: "#fff",
                          padding: "1rem",
                          borderRadius: "6px",
                        }}
                      >
                        <div className="detail-item mb-2">
                          <small className="text-muted">LIPA NAMBA</small>
                          <p className="mb-0 fw-bold">35344092</p>
                        </div>
                        <div className="detail-item mb-2">
                          <small className="text-muted">Name</small>
                          <p className="mb-0">LIPA EAST AFRICAN YOUTH PANEL</p>
                        </div>
                        <div className="detail-item">
                          <small className="text-muted">Amount</small>
                          <p
                            className="mb-0 fw-bold"
                            style={{ color: "#6e1920" }}
                          >
                            150,000/= TZS
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bank Payment Method */}
                  <div className="col-md-6">
                    <div
                      className="payment-method h-100"
                      style={{
                        backgroundColor: "#f8f9fa",
                        borderRadius: "8px",
                        padding: "1.5rem",
                        border: "1px solid #e9ecef",
                        transition: "transform 0.2s ease",
                        cursor: "pointer",
                      }}
                    >
                      <div className="method-icon mb-3">
                        <i
                          className="fas fa-university"
                          style={{
                            fontSize: "2rem",
                            color: "#6e1920",
                          }}
                        ></i>
                      </div>
                      <h6 className="method-title mb-4">CRDB Bank</h6>
                      <div
                        className="payment-details"
                        style={{
                          backgroundColor: "#fff",
                          padding: "1rem",
                          borderRadius: "6px",
                        }}
                      >
                        <div className="detail-item mb-2">
                          <small className="text-muted">Account Number</small>
                          <p className="mb-0 fw-bold">015C934133900</p>
                        </div>
                        <div className="detail-item mb-2">
                          <small className="text-muted">Name</small>
                          <p className="mb-0">East African Youth Panel</p>
                        </div>
                        <div className="detail-item">
                          <small className="text-muted">Amount</small>
                          <p
                            className="mb-0 fw-bold"
                            style={{ color: "#6e1920" }}
                          >
                            150,000/= TZS
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Data Protection and Privacy Statement  */}
            <div
              className="form-section mb-4"
              style={{
                background: "#fff",
                padding: "2rem",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
              }}
            >
              <h4
                className="mb-4"
                style={{
                  color: "#6e1920",
                  borderBottom: "2px solid #6e1920",
                  paddingBottom: "0.5rem",
                }}
              >
                Data Protection and Privacy Statement
              </h4>

              <div className="privacy-content d-flex flex-column align-items-center mb-4">
                <img
                  src={logo}
                  alt="EAYP Logo"
                  style={{
                    height: "80px",
                    marginBottom: "2rem",
                  }}
                />

                <div
                  className="privacy-text mb-4 px-4"
                  style={{
                    backgroundColor: "#f8f9fa",
                    padding: "2rem",
                    borderRadius: "8px",
                    border: "1px solid #e9ecef",
                    textAlign: "justify",
                    lineHeight: "1.6",
                  }}
                >
                  <p>
                    We are committed to protecting your personal information and
                    ensuring your privacy. The data collected in this form will
                    be used solely for the purposes of the East African Youth
                    Panel (EAYP) event.
                  </p>
                  <p className="mb-0">
                    Your information will be stored securely and will not be
                    shared with third parties without your consent. By checking
                    the box below and submitting this form, you agree to our
                    data protection policy.
                  </p>
                </div>

                <div
                  className="privacy-consent"
                  style={{ width: "100%", maxWidth: "800px", margin: "0 auto" }}
                >
                  <div className="d-flex align-items-center justify-content-center text-wrap">
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        id="privacy-consent"
                        checked={privacyConsent}
                        label={
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "0.8rem",
                              lineHeight: "1.5",
                            }}
                          >
                            I hereby acknowledge and consent to the Data
                            Protection and Privacy Policy
                          </span>
                        }
                        {...register("privacyConsent", {
                          required:
                            "You must accept the Privacy Policy to continue",
                          onChange: (e) => {
                            setPrivacyConsent(e.target.checked);
                            trigger("privacyConsent");
                          },
                          onBlur: () => trigger("privacyConsent"),
                        })}
                        className={errors.privacyConsent ? "is-invalid" : ""}
                      />
                      {errors.privacyConsent && (
                        <Form.Text
                          className="text-danger"
                          style={{ fontSize: "0.6rem" }}
                        >
                          *{errors.privacyConsent.message}*
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="text-center mt-4 mb-5">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
                style={{
                  backgroundColor: "#6e1920",
                  border: "none",
                  padding: "10px 30px",
                  fontSize: "1.1rem",
                  opacity: isSubmitting ? 0.7 : 1,
                }}
              >
                {isSubmitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Submitting...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
        </div>
      </Container>
      {/* Add Success Modal */}
      <SuccessModal />
      <ErrorModal />
    </div>
  );
};

export default RegistrationPage;
