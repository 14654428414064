export const countries = [
  { value: "", label: "Select country" },
  { value: "tanzania", label: "Tanzania" },
  { value: "burundi", label: "Burundi" },
  { value: "comoros", label: "Comoros" },
  { value: "djibouti", label: "Djibouti" },
  { value: "eritrea", label: "Eritrea" },
  { value: "ethiopia", label: "Ethiopia" },
  { value: "kenya", label: "Kenya" },
  { value: "madagascar", label: "Madagascar" },
  { value: "malawi", label: "Malawi" },
  { value: "mauritius", label: "Mauritius" },
  { value: "mozambique", label: "Mozambique" },
  { value: "reunion", label: "Reunion" },
  { value: "rwanda", label: "Rwanda" },
  { value: "seychelles", label: "Seychelles" },
  { value: "somalia", label: "Somalia" },
  { value: "uganda", label: "Uganda" },
  { value: "zambia", label: "Zambia" },
  { value: "zimbabwe", label: "Zimbabwe" }
];