import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const pricingData = [
  {
    id: 1,
    plan: "Event Tickets",
    price: "NULL",
    features: [
      "Event Date:",
      "Close Date",
      "Refreshments",
      "Networking Opportunities",
      "Support & Assistance",
    ],
    link: "#",
  },
  {
    id: 2,
    plan: "Registration Form",
    price: "150,000/= TZS",
    features: [
      "Open Date: March 10, 2025",
      "Close Date: Will be Announced",
      "Access to Events",
      "Eligibility for Awards",
      "Support & Assistance",
    ],
    link: "openpage",
  },
  {
    id: 3,
    plan: "Vote Now",
    price: "Free",
    features: [
      "Open Date:",
      "Close Date",
      "Support Your Favorite Candidate",
      "Real-time Results",
      "Easy and Secure Voting",
    ],
    link: "#",
  },
];

const REGISTRATION_DATES = {
  OPEN: new Date("2025-03-10"),
  // CLOSE: new Date("2025-06-04"),
};

const isRegistrationOpen = () => {
  const currentDate = new Date();
  return currentDate >= REGISTRATION_DATES.OPEN;
  // return (
  //   currentDate >= REGISTRATION_DATES.OPEN &&
  //   currentDate <= REGISTRATION_DATES.CLOSE
  // );
};

// const getButtonText = (id) => {
//   if (id === 2) {
//     const currentDate = new Date();
//     if (currentDate < REGISTRATION_DATES.OPEN) {
//       return "Opens March 10, 2025";
//     } else if (currentDate > REGISTRATION_DATES.CLOSE) {
//       return "Registration Closed";
//     } else {
//       return "Apply Now";
//     }
//   }
//   return "Coming Soon";
// };

const getButtonText = (id) => {
  if (id === 2) {
    const currentDate = new Date();
    if (currentDate < REGISTRATION_DATES.OPEN) {
      return "Opens March 10, 2025";
    }
    return "Apply Now"; // Remove closing date check
  }
  return "Coming Soon";
};

const AppPricing = () => {
  // const isButtonDisabled = (id) => {
  //   if (id === 1 || id === 3) return true;
  //   if (id === 2) {
  //     const currentDate = new Date();
  //     return (
  //       currentDate < REGISTRATION_DATES.OPEN ||
  //       currentDate > REGISTRATION_DATES.CLOSE
  //     );
  //   }
  //   return false;
  // };

  return (
    <section id="pricing" className="block pricing-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Forms,Tickets &amp; Vote</h2>
          <div className="subtitle">Secure Your Spot &amp; Join Us</div>
        </div>
        <Row>
          {pricingData.map((pricing) => {
            return (
              <Col sm={4} key={pricing.id}>
                <div className="heading">
                  <h3>{pricing.plan}</h3>
                  <span className="price">{pricing.price}</span>
                </div>
                <div className="content">
                  <ListGroup>
                    {pricing.features.map((feature, index) => {
                      return (
                        <ListGroup.Item key={index}>{feature}</ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
                <div className="btn-holder">
                  {pricing.id === 2 ? (
                    isRegistrationOpen() ? (
                      <Link
                        to="/register"
                        className="btn btn-primary"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {getButtonText(pricing.id)}
                      </Link>
                    ) : (
                      <Button variant="primary" disabled={true}>
                        {getButtonText(pricing.id)}
                      </Button>
                    )
                  ) : (
                    <Button variant="primary" disabled={true}>
                      Coming Soon
                    </Button>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default AppPricing;
