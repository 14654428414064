import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function AppHeader() {
  const location = useLocation();

  useEffect(() => {
    // Handle hash navigation
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleNavClick = (e) => {
    const href = e.currentTarget.getAttribute('href');
    if (href?.startsWith('#')) {
      e.preventDefault();
      const element = document.querySelector(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <header id="header">
      <Container>
        <Navbar expand="lg">
          <Navbar.Brand as={Link} to="/">
            <img
              src="/logo192.png"
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt="EAYP Logo"
            />
            <span className="d-none d-lg-inline"> East African Youth Panel</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link href="#pricing" onClick={handleNavClick}>Events</Nav.Link>
              <Nav.Link href="#about" onClick={handleNavClick}>About</Nav.Link>
              <Nav.Link href="#founder" onClick={handleNavClick}>Founder</Nav.Link>
              <Nav.Link href="#services" onClick={handleNavClick}>Services</Nav.Link>
              <Nav.Link href="#works" onClick={handleNavClick}>Gallery</Nav.Link>
              <Nav.Link href="#testimonials" onClick={handleNavClick}>Testimonials</Nav.Link>
              <Nav.Link href="#sponsors" onClick={handleNavClick}>Sponsors</Nav.Link>
              <Nav.Link href="#contact" onClick={handleNavClick}>Contact</Nav.Link>
              <Nav.Link as={Link} to="/register">Register</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}

export default AppHeader;
