import React from 'react';
import AppHero from '../components/hero';
import AppAbout from '../components/about';
import Founder from '../components/founder';
import AppServices from '../components/services';
import AppWorks from '../components/works';
// import AppTeams from './components/teams';
import AppTestimonials from '../components/testimonials';
import AppPricing from '../components/pricing';
// import AppBlog from './components/blog';
import SponsorsPage from '../components/sponsors';
import AppContact from '../components/contact';
 


const HomePage = () => {
  return (
    <>
      <AppHero />
      <AppPricing />
      <AppAbout />
      <Founder />
      <AppServices />
      <AppWorks />
         {/* <AppTeams /> */}
      <AppTestimonials />
      {/* <AppBlog /> */}
      <SponsorsPage/>
      <AppContact />
    </>
  );
};

export default HomePage;