import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Image } from "react-bootstrap";
import jwLogo from "../assets/images/jw.png";

function AppFooter() {
  const currentYear = new Date().getFullYear();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const APP_VERSION = "0.1.4"; // Define version constant at component level

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  function goTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <footer id="footer">
      <Container fluid>
        <div className="copyright">
          &copy; {currentYear} East African Youth Panel. All Rights Reserved.
        </div>

        <div className="socials">
          <ul>
            <li>
              <a href="https://www.instagram.com/reel/C1EtM5OMEXl/?igsh=MzRlODBiNWFlZA==">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://vm.tiktok.com/ZMrHd7DoB">
                <i className="fab fa-tiktok"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="developer-info">
          <p style={{ padding: "20px" }}>
            Developed by{" "}
            <b>Japhets Wings</b>
          </p>
          <a
            href="https://www.japhetswings.co.tz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={jwLogo}
              alt="Japhets Wings Logo"
              style={{ width: "100px", height: "auto", margin: "-40px" }}
            />
          </a>
          <p style={{ 
            fontSize: "12px", 
            color: "#666", 
            marginTop: "30px",
            textAlign: "center" 
          }}>
            Version {APP_VERSION}
          </p>
        </div>
        {showTopBtn && <div className="go-top" onClick={goTop}></div>}
      </Container>
    </footer>
  );
}

export default AppFooter;
